//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmpMainMenu from '~/components/common/layout/EmpMainMenu';
document.querySelector('body').classList.add('loaded');
import { mapGetters } from 'vuex';
import * as ptypes from '~/utilities/props-types';

export default {
    components: {
        EmpMainMenu
    },
    data: function() {
        return {
            isHome: ptypes.tBOOLEAN,
            isFullwidth: ptypes.fBOOLEAN,
            uri: window.location.pathname,

            fullName: localStorage.getItem('fullName'),
            extrabase: localStorage.getItem('extrabase')
                ? localStorage.getItem('extrabase')
                : 'higher',
            done: false,
            k: 0
        };
    },
    watch: {
        $route: async function() {
            this.uri = window.location.pathname;
            if (this.$route.path.includes('fullwidth')) {
                this.isFullwidth = true;
            } else this.isFullwidth = false;
            if (this.$isEmployer) {
                await this.$checkSessionMiddleware(this.uri);
                this.k++;
            }
        }
    },
    props: {
        chatCount: ptypes.vNUMBER(0),
        notificationCount: ptypes.vNUMBER(0)
    },

    computed: {
        ...mapGetters('cart', ['totalCount']),
        emp() {
            return window.location.href.indexOf('/employer-porta') > -1;
        }
    },
    mounted: async function() {
        if (this.$route.path.includes('fullwidth')) {
            this.isFullwidth = true;
        } else this.isFullwidth = false;
        const myImage = new Image();
        myImage.src = '/static/images/e/employer.webp';
        myImage.onload = () => {
            this.done = true;
        };

        myImage.onerror = () => {
            this.done = true;
        };
        await this.$checkSession();
        this.k++;
    },
    methods: {
        showMobileMenu: function() {
            document.querySelector('body').classList.add('mmenu-active');
        }
    }
};
